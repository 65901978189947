<template>
  <div id="app">
	<div :class="$route.name ? 'content content-tab':'content'" >
		<router-view :userpoint='userpoint.point'  :user='userinfo'/>
	</div>
    <nav class="tab" v-if='$route.name'>
      <router-link to="/">
		  <img v-if='$route.name == "index"' src="./assets/fyb_2.png" alt="">
		  <img v-else src="./assets/fyb_1.png" alt="">
		  <span :class='$route.name == "index" ? "act":""'>操盘风云</span>
	  </router-link> 
      <router-link to="/competition">
		  <img v-if='$route.name == "competition"' src="./assets/pm_2.png" alt="">
		  <img v-else src="./assets/pm_1.png" alt="">
		  <span :class='$route.name == "competition" ? "act":""'>实盘大赛</span>
	  </router-link>
	  <router-link to="/apply">
	  		  <img v-if='$route.name == "apply"' src="./assets/jzy_2@2x.png" alt="">
	  		  <img v-else src="./assets/jzy_1@2x.png" alt="">
	  		  <span :class='$route.name == "apply" ? "act":""'>报单</span>
	  </router-link>
	  <router-link to="/user">
	  		  <img v-if='$route.name == "user"' src="./assets/wd_2@2x.png" alt="">
	  		  <img v-else src="./assets/wd_1@2x.png" alt="">
	  		  <span :class='$route.name == "user" ? "act":""'>个人中心</span>
	  </router-link>
    </nav>
    
  </div>
</template>

<script>
export default {
	data(){
		return{
			userinfo:{},
			userpoint:{}
		}
	},
	watch: {
		$route(){
			this.getPoint()
		}
	},
	methods:{
		getPoint(){
			this.$api.getPoint().then(res=>{
				if(res.data.code == 0){
					this.userpoint=res.data.data
				}
			})
		},
		checkPoint(parm){
			if(this.userpoint.point < 1){
				this.$message.error('积分不足,请先去购买！')
				this.$router.push({path:'/buy'})
			}else{
				var flag=localStorage.getItem(parm.origin)
				console.log(flag)
				if(!flag){
					localStorage.setItem(parm.origin,1)
					this.changePoint(parm)
				}
			}
		},
		changePoint(parm){
			this.$api.changePoint(parm).then(res=>{
				
			})
		}
	},
	mounted(){
		
	},
	created(){
		var userinfo=localStorage.getItem('userinfo')
		if(userinfo){
			this.userinfo=JSON.parse(userinfo)
		}
		
	},
}	
</script>

<style lang="scss">
	*{
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		list-style: none;
	}
	body{
		background-color: #f5f5f5;
		max-width: 540px;
		
	}
	input{
		font-size: 15px !important;
	}
	.content{
		height: 100vh;
		overflow-y: auto;
		padding-bottom: 0;
		position: relative;
		.up{
		  color: #E90001 !important;
		}
		.down{
		  color: #07B20B !important;
		}
	}
	.content-tab{
		height: calc(100vh - 50px);
	}
	.tab{
		position: fixed;
		max-width: 540px;
		width: 100%;
		height: 50px;
		background-color: #fff;
		border-top: 1px solid #d7d7d7;
		left: 0;
		bottom: 0;
		z-index: 99999;
		display: flex;
		padding: 5px 0;
		a{
			width: 25%;
			text-align: center;
			color: #666;
			text-decoration: none;
			font-size: 12px;
		}
		.act{
			color: #ff0a01;
		}
		img{
			display: block;
			width: 25px;
			margin:0 auto;
		}
	}
</style>
