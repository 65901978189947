import axios from 'axios';
var baseURL='https://match.caopanfengyun.com'
// baseURL=''
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        	config.headers.Authorization = `${token}`;   
        } 
        return config;
    },
    err => {
        return Promise.reject(err);
    });

axios.interceptors.response.use(function (response) {
    // IE 8-9 
    if (response.data == null && response.config.responseType === 'json' && response.request.responseText != null) {
        try {
            // eslint-disable-next-line no-param-reassign
            response.data = JSON.parse(response.request.responseText);
        } catch (e) {
            // ignored
        }
    }
    // 对响应数据做点什么
    if (response.data.code == 403) {
		// if(!sessionStorage.getItem('flag')){
		// 	window.location.hash = "/login";
		// 	sessionStorage.setItem('flag',1)
		// }
        
        // window.location.hash = "/";
    }
    if (response.data.code == 305) {
        // window.location.hash = "/";
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    if (error.request.status == 403) {
        // window.location.hash = "/login";
        // window.location.hash = "/";
    }
    return Promise.reject(error);
});
export default {
  login: params => {
      return axios.post(`${baseURL}/api/wx/v3/login`, params);
  },
  sendCode: params => {
      return axios.post(`${baseURL}/api/wx/v3/phone/code`, params);
  },
  getAuthinfo: params => {
    return axios.get(`${baseURL}/api/wx/v3/authinfo`, { params: params })
  },
  getUserinfo: params => {
    return axios.get(`${baseURL}/api/wx/authinfo`, { params: params })
  },
  getUser: params => {
    return axios.get(`${baseURL}/api/wx/user/info`, { params: params })
  },
  getPoint: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/point`, { params: params })
  },
  getDate: params => {
    return axios.get(`${baseURL}/api/wx/v2/default/date`, { params: params })
  },
  getArticle: params => {
    return axios.get(`${baseURL}/api/wx/v2/champion/article/list`, { params: params })
  },
  getWinLose: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/group/winlose/statistic`, { params: params })
  },
  getWinLost: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/winlost/top5`, { params: params })
  },
  getAvg: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/group/winlose/avg/allday`, { params: params })
  },
  getAvg1: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/group/winlose/allday`, { params: params })
  },
  
  getHot: params => {
    return axios.get(`${baseURL}/api/wx/v2/hot/stock/buy/list`, { params: params })
  },
  getFyb: params => {
    return axios.get(`${baseURL}/api/wx/v3/profit/rank/list`, { params: params })
  },
  getLyb: params => {
    return axios.get(`${baseURL}/api/wx/v2/hot/follow/list`, { params: params })
  },
  getTodayStock: params => {
    return axios.get(`${baseURL}/api/wx/player/match`, { params: params })
  },
  getStockPlayer: params => {
    return axios.get(`${baseURL}/api/wx/v3/stock/players/list`, { params: params })
  },
  getCalendar: params => {
    return axios.get(`${baseURL}/api/wx/v2/player/match/calendar`, { params: params })
  },
  getZanCount: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/zancount`, { params: params })
  },
  getComment: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/comments/list`, { params: params })
  },
  getMatch: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/match/list`, { params: params })
  },
  getUserRank: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/match/stock/winlossrank/list`, { params: params })
  },
  getArticleDetail: params => {
    return axios.get(`${baseURL}/api/wx/v3/article/detail`, { params: params })
  },
  getRank: params => {
    return axios.get(`${baseURL}/api/wx/v3/supermatch/list`, { params: params })
  },
  getSupermatch: params => {
    return axios.get(`${baseURL}/api/wx/v3/supermatch`, { params: params })
  },
  getRankList: params => {
    return axios.get(`${baseURL}/api/wx/group/rank/list`, { params: params })
  },
  getGroupList: params => {
    return axios.get(`${baseURL}/api/wx/group/rank`, { params: params })
  },
  getGroup: params => {
    return axios.get(`${baseURL}/api/wx/v3/supermatch/aggs`, { params: params })
  },
  getMatchList: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/list`, { params: params })
  },
  getAbleSign: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/able-to-signup/list`, { params: params })
  },
  
  getExperience: params => {
    return axios.get(`${baseURL}/api/wx/v3/experience/list`, { params: params })
  },
  imageOcr: params => {
      return axios.post(`${baseURL}/api/wx/v3/ai/ocr`, params);
  },
  postRecord: params => {
      return axios.post(`${baseURL}/api/wx/player/record`, params);
  },
  getStyleList: params => {
    return axios.get(`${baseURL}/api/wx/v2/enum/list`, { params: params })
  },
  postStyle: params => {
      return axios.put(`${baseURL}/api/wx/v2/mine/style`, params);
  },
  update: params => {
      return axios.put(`${baseURL}/api/wx/v3/user/fund/modify`, params);
  },
  
  getArticleList: params => {
    return axios.get(`${baseURL}/api/wx/v2/champion/article/list`, { params: params })
  },
  getPointList: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/point/record/list`, { params: params })
  },
  follow: params => {
      return axios.post(`${baseURL}/api/wx/v2/user/follow`, params);
  },
  black: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/black`, params);
  },
  getFollowList: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/follow/list`, { params: params })
  },
  getProductList: params => {
    return axios.get(`${baseURL}/api/wx/v3/product/list`, { params: params })
  },
  changePoint: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/point`, params);
  },
  postConsult: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/consult`, params);
  },
  getConsultList: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/consult/list`, { params: params })
  },
  postComment: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/comments`, params);
  },
  delComment: params => {
      return axios.delete(`${baseURL}/api/wx/v3/user/comments`, { params: params });
  },
  postZan: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/zan`, params);
  },
  getMGroupList: params => {
    return axios.get(`${baseURL}/api/wx/v3/match/group/list`, { params: params })
  },
  signup: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/point/match/signup`, params);
  },
  getBlackList: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/black/list`, { params: params })
  },
  getisabletosignup: params => {
    return axios.get(`${baseURL}/api/wx/v3/user/match/isabletosignup`, { params: params })
  },
  getRecordList: params => {
    return axios.get(`${baseURL}/api/wx/v3/player/record/list`, { params: params })
  },
  pay: params => {
      return axios.post(`${baseURL}/api/wx/v3/user/point/h5buy`, params);
  },
  payfui: params => {
      return axios.post(`/aggh5Gate.fuiou`, params);
  },
}
